const projects = [
  {
    id: 1,
    title: "Booki",
    description: "Site de réservation d'hébergements (HTML/CSS)",
    descriptionTitle: "Projet 2 du parcours Développeur Web d'OpenClassrooms",
    descriptionDetails: [
      "Développement en HTML5 et CSS3",
      "Intégration de la maquette responsive en HTML et CSS",
      "Respect des normes W3C et des bonnes pratiques SEO",
      "Optimisation SEO et accessibilité du site",
    ],
    tags: ["HTML", "CSS"],
    languages: ["HTML", "CSS"],
    type: "frontend",
    reason: "Etudiant",
    startDate: "06/04/2024",
    endDate: "10/05/2024",
    timeRange: "35 jours",
    githubLink: "https://github.com/wolfox-mael/openclassrooms.projet2",
    demoLink: "https://maelarrault.fr/projects/openclassrooms.projet2/",
    thumbnail: "/images/projects/booki-thumb.jpg",
    previewIMG: "/images/projects/booki-preview.png",
    complexity: "beginner",
  },
  {
    id: 2,
    title: "Sophie Bluel Portfolio",
    description: "Portfolio d'architecte avec backend",
    descriptionTitle: "Projet 3 du parcours Développeur Web d'OpenClassrooms",
    descriptionDetails: [
      "Manipuler les éléments du DOM avec JavaScript",
      "Gérer les événements du DOM avec JavaScript",
      "Création de la page de contact avec formulaire (non utilisable)",
      "Création d'une page de blog des projets de l'architecte",
    ],
    tags: ["JavaScript", "Node.js", "Express"],
    languages: ["HTML", "CSS", "JavaScript"],
    type: "fullstack",
    reason: "Etudiant",
    startDate: "11/05/2024",
    endDate: "07/06/2024",
    timeRange: "28 jours",
    githubLink: "https://github.com/wolfox-mael/openclassrooms.projet3",
    demoLink: "",
    thumbnail: "/images/projects/sophie-thumb.jpg",
    previewIMG: "/images/projects/sophie-preview.png",
    complexity: "intermediate",
  },
  {
    id: 3,
    title: "Nina Carducci Portfolio",
    description: "Portfolio de photographe optimisé SEO/Performance",
    tags: ["HTML", "CSS", "JavaScript", "SEO", "Performance"],
    descriptionTitle: "Projet 4 du parcours Développeur Web d'OpenClassrooms",
    descriptionDetails: [
      "Optimisation du SEO",
      "Optimisation de l'accessibilité",
      "Optimisation de la compatibilité mobile",
      "Optimisation de la performance",
      "Débogage et optimisation du code",
      "Optimisation des images du site avec le format WebP",
    ],
    languages: ["HTML", "CSS", "JavaScript"],
    type: "frontend",
    reason: "Etudiant",
    startDate: "08/06/2024",
    endDate: "28/06/2024",
    timeRange: "21 jours",
    githubLink: "https://github.com/wolfox-mael/openclassrooms.projet4",
    demoLink: "https://maelarrault.fr/projects/openclassrooms.projet4/",
    thumbnail: "/images/projects/nina-thumb.jpg",
    previewIMG: "/images/projects/nina-preview.png",
    complexity: "intermediate",
  },
  {
    id: 4,
    title: "Kasa",
    description: "Application web de location immobilière",
    tags: ["React", "SASS", "JavaScript"],
    descriptionTitle: "Projet 5 du parcours Développeur Web d'OpenClassrooms",
    descriptionDetails: [
      "Développement en React avec Create React App",
      "Gestion des composants React",
      "Configuration de la navigation avec React Router",
      "Utilisation de SASS pour les styles",
      "Creation d'animations avec SASS",
    ],
    languages: ["HTML", "CSS", "JavaScript"],
    type: "frontend",
    reason: "Etudiant",
    startDate: "29/06/2024",
    endDate: "26/07/2024",
    timeRange: "28 jours", 
    githubLink: "https://github.com/wolfox-mael/openclassrooms.projet5",
    demoLink: "https://maelarrault.fr/projects/openclassrooms.projet5/",
    thumbnail: "/images/projects/kasa-thumb.jpg",
    previewIMG: "/images/projects/kasa-preview.png",
    complexity: "advanced",
  },
  {
    id: 5,
    title: "Mon Vieux Grimoire",
    description: "Site de notation de livres avec backend sécurisé",
    tags: ["Node.js", "Express", "MongoDB", "API REST"],
    descriptionTitle: "Projet 6 du parcours Développeur Web d'OpenClassrooms",
    descriptionDetails: [
      "Création d'une API REST sécurisée",
      "Gestion des livres et des notes",
      "Mettre en place un système de notation des livres",
      "Mettre en place des opération CRUD sécurisées",
      "Gestion des utilisateurs",
      "Stockage des données dans une base MongoDB",
      "Stockage des données de manière sécurisée",
      "Développement en Node.js et Express",
    ],
    languages: ["HTML", "CSS", "JavaScript"],
    type: "fullstack",
    reason: "Etudiant",
    startDate: "27/07/2024",
    endDate: "23/08/2024",
    timeRange: "28 jours",
    githubLink: "https://github.com/wolfox-mael/openclassrooms.projet6",
    demoLink: "",
    thumbnail: "/images/projects/grimoire-thumb.jpg",
    previewIMG: "/images/projects/grimoire-preview.png",
    complexity: "advanced",
  },
  {
    id: 6,
    title: "Gestion Agile de projet",
    description: "Planification du développement d'un site web",
    tags: ["Tableau Kanban", "Gestion de projet"],
    descriptionTitle: "Planifiez le développement du site de votre client",
    descriptionDetails: [
      "Analyse des besoins du client et de l'utilisateur final du site",
      "Analyse des besoin API pour le projet",
      "Création d'un tableau Kanban pour la gestion du projet",
      "Planification du développement du site",
      "Gestion de projet et rédaction de documentation technique",
      "Mise en place d'un système de veille technologique",
    ],
    languages: ["HTML", "CSS", "JavaScript"],
    type: "fullstack",
    reason: "Etudiant",
    startDate: "24/08/2024",
    endDate: "06/09/2024",
    timeRange: "14 jours",
    githubLink: "",
    demoLink: "",
    thumbnail: "/images/projects/plan-thumb.png",
    previewIMG: "/images/projects/plan-preview.png",
    complexity: "advanced",

  }
];

export default projects;
