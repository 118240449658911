import React, { useEffect } from "react";
import "./index.scss";

const About = () => {

  return (
    <section id="about">
      <div id="more-about-me">
        <h2>À propos de moi</h2>
        <img
          src="/images/ProfilePicture.png"
          alt="Profile"
          className="profile-picture"
        />
        <p>
          Bonjour, je suis Mael Arrault, un développeur web fullstack passionné
          avec de l'expérience dans la création d'applications web modernes.
          <br />
          Je me spécialise en JavaScript, React et Node.js.
          <br />
          Je vous propose de découvrir mes projets et de me contacter pour
          discuter de vos besoins.
          <br />
          N'hésitez pas à consulter mon CV pour en savoir plus sur mon parcours.
          <br />
          Il est disponible en téléchargement ci-dessous.
        </p>
        <a href="/cv-mael-arrault.pdf" target="_blank" rel="noopener noreferrer" className="cv-link">
          Voir mon CV
        </a>
      </div>
      <div id="informations">
        <div>
          <h2>Compétences</h2>
          <ul>
            <li>JavaScript</li>
            <li>React</li>
            <li>Node.js</li>
            <li>HTML/CSS</li>
            <li>Git</li>
          </ul>
        </div>
        {/* <div>
          <h2>Expérience professionnelle</h2>
          <ul>
          </ul>
        </div> */}
        <div>
          <h2>Formation</h2>
          <ul>
            <li>Formation Développeur Web (Fullstack) - OpenClassrooms - 26 mars 2024 - en cours</li>
          </ul>
        </div>
      </div>

      
    </section>
  );
};

export default About;
