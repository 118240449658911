import React from "react";
import "./index.scss";

const Footer = () => {
  return (
    <footer>
      <div id="content-wrapper">
      <div className="social-media">
        <a href="https://github.com/wolfox-mael" title="GitHub">
          <i className="fa-brands fa-github"></i>
        </a>
        <a href="https://linkedin.com/in/mael-arrault" title="LinkedIn">
          <i className="fa-brands fa-linkedin"></i>
        </a>
        <a href="https://twitter.com/Wolfox_mael" title="Twitter">
          <i className="fa-brands fa-twitter"></i>
        </a>
      </div>
      <p>&copy; 2024 Mael Arrault. Tous droits réservés.</p></div>
    </footer>
  );
};

export default Footer;
