import { useState, useEffect } from "react";

const Github = () => {
  const [githubContributions, setGithubContributions] = useState([]);
  const githubUsername = "wolfox-mael";

  useEffect(() => {
    // Fetch GitHub contributions
    fetch(`https://api.github.com/users/${githubUsername}/events`)
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data.filter(
          (contribution) =>
            contribution.repo && contribution.actor.login !== githubUsername
        );
        setGithubContributions(filteredData);
      })
      .catch((error) =>
        console.error("Error fetching GitHub contributions:", error)
      );
  }, []);

  // Get unique repository names and additional information
  const uniqueRepos = Array.from(
    new Set(githubContributions.map((contribution) => contribution.repo.name))
  ).map((repoName) => {
    const contribution = githubContributions.find(
      (contribution) => contribution.repo.name === repoName
    );
    return {
      name: repoName,
      url: `https://github.com/${contribution.repo.name}`,
      owner: contribution.actor.login,
    };
  });

  if (uniqueRepos.length === 0) {
    return null; // Return nothing if no contributions are found
  }

  console.log(uniqueRepos);

  return (
    <div>
      <h2>Contributions GitHub</h2>
      <div className="grid-container">
        {uniqueRepos.map((repo, index) => (
          <div key={index} className="grid-item">
            <p><strong>Repository:</strong> <a href={repo.url} target="_blank" rel="noopener noreferrer">{repo.name}</a></p>
            <p><strong>Owner:</strong> {repo.owner}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Github;
