import React from "react";
import ReactDOM from "react-dom/client";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import "./index.scss";

const App = () => {
  return (
    <div className="app-wrapper">
      <Header />
       <main>
        <Home />
        <About />
        <div id="separator">
          <h2>Logiciels que j'utilise</h2>
          <ul>
            <li>Visual Studio Code</li>
            <li>Git</li>
            <li>GitHub</li>
            <li>Gitlab</li>
            <li>WinSCP</li>
            <li>Postman</li>
            </ul>          
        </div>
      </main>
      <Projects />
      <Footer /> 
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);