import { useState, useEffect } from "react";

const Gitlab = () => {
  const [gitlabContributions, setGitlabContributions] = useState([]);
  const gitlabUsername = "maelarrault";

  useEffect(() => {
    // Fetch GitLab contributions
    fetch(`https://gitlab.com/api/v4/users/${gitlabUsername}/events`)
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data.filter(
          (contribution) =>
            contribution.project && contribution.author.username !== gitlabUsername
        );
        setGitlabContributions(filteredData);
      })
      .catch((error) =>
        console.error("Error fetching GitLab contributions:", error)
      );
  }, []);

  // Get unique project names and additional information
  const uniqueProjects = Array.from(
    new Set(gitlabContributions.map((contribution) => contribution.project.name))
  ).map((projectName) => {
    const contribution = gitlabContributions.find(
      (contribution) => contribution.project.name === projectName
    );
    return {
      name: projectName,
      url: contribution.project.web_url,
      owner: contribution.author.username,
    };
  });

  if (uniqueProjects.length === 0) {
    return null; // Return nothing if no contributions are found
  }

  return (
    <div>
      <h2>Contributions GitLab</h2>
      <div className="grid-container">
        {uniqueProjects.map((project, index) => (
          <div key={index} className="grid-item">
            <p><strong>Project:</strong> <a href={project.url} target="_blank" rel="noopener noreferrer">{project.name}</a></p>
            <p><strong>Owner:</strong> {project.owner}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gitlab;
