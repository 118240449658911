import PropTypes from "prop-types";
import "./index.scss";
import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");
function ProjectCard({ project }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
    document.body.classList.add("no-scroll");
  };

  const closeModal = () => {
    setModalIsOpen(false);
    document.body.classList.remove("no-scroll");
  };

  return (
    <article className="project-card">
      <img
        src={project.thumbnail}
        alt={project.title}
        className="project-thumbnail"
      />
      <button onClick={openModal} className="open-modal-button">
        Voir plus
      </button>
      <div className="project-info">
        <h3>{project.title}</h3>
        <p>{project.description}</p>
        <div className="tags">
          {project.tags.map((tech) => (
            <span key={tech} className="tech-tag">
              {tech}
            </span>
          ))}
        </div>
        <div className="type-time">
          <span>{project.reason}</span>
          <span>
            {project.startDate} - {project.timeRange}
          </span>
        </div>
        <div className="project-links">
          {project.githubLink === "" ? (
            ""
          ) : (
            <a
              href={project.githubLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-github"></i> Code
            </a>
          )}
          {project.demoLink === "" ? (
            ""
          ) : (
            <a
              href={project.demoLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-solid fa-link"></i> Demo
            </a>
          )}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Project Preview"
        className={"project-modal"}
      >
        <h2>{project.title}</h2>
        <p>{project.description}</p>
        <img
          src={project.previewIMG}
          alt={project.title}
          className="project-preview-image"
        />
        <div className="project-details">
          <ul className="tags">
            {project.tags.map((tech) => (
              <li key={tech} className="tech-tag">
                {tech}
              </li>
            ))}
          </ul>
          <div className="description">
            <h3>{project.descriptionTitle}</h3>
            <ul>
              {project.descriptionDetails.map((detail) => (
                <li key={detail}>{detail}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="project-links">
          {project.githubLink === "" ? (
            ""
          ) : (
            <a
              href={project.githubLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-github"></i> Code
            </a>
          )}
          {project.demoLink === "" ? (
            ""
          ) : (
            <a
              href={project.demoLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-solid fa-link"></i> Demo
            </a>
          )}
        </div>
        <button onClick={closeModal} className="close-button">
          Fermer
        </button>
      </Modal>
    </article>
  );
}

ProjectCard.propTypes = {
  project: PropTypes.shape({
    thumbnail: PropTypes.string.isRequired,
    previewIMG: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    descriptionTitle: PropTypes.string.isRequired,
    descriptionDetails: PropTypes.arrayOf(PropTypes.string).isRequired,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    reason: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    timeRange: PropTypes.string.isRequired,
    githubLink: PropTypes.string.isRequired,
    demoLink: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProjectCard;
