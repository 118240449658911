import React from "react";
import "./index.scss";

const Home = () => {
  return (
    <section id="home">
      <h1>Bienvenue sur mon portfolio</h1>
    </section>
  );
};

export default Home;
