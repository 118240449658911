import "./index.scss";
import { useState } from "react";
import ProjectCard from "../../components/ProjectCard";
import ProjectFiltersType from "../../components/ProjectFilters";
import projects from "../../datas/projects";
import GitGrid from "../../components/GitGrid";


function Projects() {
  const [filter, setFilter] = useState('all');
  const filteredProjects = projects.filter(project => filter === 'all' || project.languages.includes(filter));
  return (
    <main>
      <section id="projects">
        <h2>Mes Projets</h2>
        <GitGrid />
        <h3>Filtres</h3>
        <ProjectFiltersType onFilterChange={setFilter} selected={filter} />
        <div className="projects-grid">
          {filteredProjects.map(project => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </div>
      </section>
    </main>
  );
}

export default Projects;