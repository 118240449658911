import projects from "../../datas/projects";
import "./index.scss";
import PropTypes from 'prop-types';

function ProjectFilters({ onFilterChange, selected}) {    
    return (
        <div className="filters">
            <div className="filters-buttons">
                <button onClick={() => onFilterChange("all")} className={selected === "all" ? "active" : ""}>Tous</button>
                {[...new Set(projects.flatMap((project) => project.languages))].map(
                    (languages) => (
                        <button key={languages} onClick={() => onFilterChange(languages)} className={selected === languages ? "active" : ""}>
                            {languages}
                        </button>
                    )
                )}
            </div>
        </div>
    );
}

ProjectFilters.propTypes = {
    onFilterChange: PropTypes.func.isRequired,
    selected: PropTypes.string.isRequired,
};

export default ProjectFilters;