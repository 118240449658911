import "./index.scss";
import Gitlab from "../Gitlab";
import Github from "../Github";

const GitGrid = () => {

  

  return (
    <div className="git-grid">
            <Gitlab />
            <Github />
    </div>
  );
};

export default GitGrid;
