import React, { useState } from "react";
import Modal from "react-modal";
import "./index.scss";

Modal.setAppElement("#root");

function Header() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const form = document.getElementById("contactForm");

  const openModal = () => {
    setModalIsOpen(true);
    document.body.classList.add("no-scroll");
  };

  const closeModal = () => {
    setModalIsOpen(false);
    document.body.classList.remove("no-scroll");
  };

  const handleCopyEmail = () => {
    navigator.clipboard.writeText("maelarrault@gmail.com");
    setShowCopyMessage(true);
    setTimeout(() => setShowCopyMessage(false), 2000); // Masquer le message après 2 secondes
  };

  async function sendEmail(data) {
    console.log("Données envoyées :", data);

    const response = await fetch("https://api.maelarrault.fr/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(data),
    });

    console.log("Réponse du serveur :", response);

    if (response.ok) {
      alert("Votre message a bien été envoyé !");
      setEmail("");
      setName("");
      setSubject("");
      setMessage("");
      closeModal();
    } else {
      const errorMessage = await response.text();
      console.error("Erreur :", errorMessage);
      alert(`Une erreur est survenue : ${errorMessage}`);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email,
      name,
      subject,
      message,
    };

    console.log(data);

    sendEmail(data);
  };

  return (
    <header>
      <div id="content-wrapper">
        <div>
          <h1>Mael Arrault</h1>
          <p>Fullstack Web Developer</p>
        </div>
        <nav>
          <ul>
            <li>
              <a href="#home">Accueil</a>
            </li>
            <li>
              <a href="#about">A propos</a>
            </li>
            <li>
              <a href="#projects">Projets</a>
            </li>
          </ul>
        </nav>
        <button onClick={openModal} title="Ouvrir le formulaire de contact">
          Me contacter
        </button>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Contact Form"
          className={"contact-modal"}
        >
          <h2 className="popup">Contact Mael Arrault</h2>
          <div className="copy-container">
            <button
              onClick={handleCopyEmail}
              className="copy-button"
              title="Copier l'adresse email"
            >
              <p>
                contact@maelarrault.fr{" "}
                <i className="fa-regular fa-clipboard"></i>
              </p>
            </button>
            {showCopyMessage && (
              <span className="copy-message">Adresse email copiée !</span>
            )}
          </div>
          <form onSubmit={handleSubmit} className="contact-form" id="contactForm">
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="firstName">Nom:</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="subject">Sujet:</label>
              <input
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </div>
            <div className="buttons">
              <button type="submit" className="submit-button">
                Envoyer
              </button>
              <button onClick={closeModal} className="close-button">
                Fermer
              </button>
            </div>
          </form>
        </Modal>
      </div>
    </header>
  );
}

export default Header;
